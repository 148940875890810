/* MEDIA BREAKPOINTS
-------------------------------------------------- */

// ### DOWN
//
// From large to small devices

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) { ... }`
@include media-breakpoint-down(sm) {
  #banners {
    .bg-img {
      background-position: 80% 100%;
    }
  }
  #client {
    .border-3 {
      border: 0;
    }
    .konsultasikan {
      text-align: center;
    }
  }
  #feature {
    &.warehouse-page {
      &>.container>.row {
        margin-top: -20px;
      }
    }
  }
  #platform {
    .platform-slider {
      .item {
        padding: 0;
      } 
    }
  }
  #about-text {
    background-position: 60% 0;
    .page-title {
      padding-top: 5.5rem;
    }
  }
  #cek-resi {
    h2 {
      text-align: center;
    }
  }
  #jadi-partner {
    .step {
      width: 34px;
    }
  }
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(lg) {
  .navbar-nav {
    height: calc(100vh - 86px);
  }
}

@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(xxl) {}

// ### UP
//
// From small to large devices

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {
  .visi-misi-wrapper {
    position: relative;
  }

  .visi-misi-content {
    position: absolute;
    bottom: -1rem;
  }

  #partner-feature {
    .card {
      margin-top: -90px;
    }
  }

  #jadi-partner {
    .item {
      &::after {
        content: '';
        border-width: 3px 0 0 0;
        border-color: $white;
        border-style: solid;
        width: 100%;
        position: absolute;
        bottom: 21px;
        transform: translate(10px, 0);
      }

      &.round {
        &::after {
          width: 232px;
          height: 124px;
          border-top-right-radius: 120px;
          border-top-left-radius: 120px;
          border: 3px solid $white;
          border-bottom: 0;
          transform: rotate(90deg);
          transform: translate(-34px, 142%) rotate(90deg);
          position: absolute;
        }
      }

      &.no-border {
        &::after {
          border: 0;
        }
      }
    }
  }
  #platform {
    .figure-img {
      width: 510px;
    }
  }
}

@include media-breakpoint-up(lg) {}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {}

// ### ONLY
//
// Only breakpoints

@include media-breakpoint-only(xs) {}

@include media-breakpoint-only(sm) {}

@include media-breakpoint-only(md) {}

@include media-breakpoint-only(lg) {
  #platform figure {
  width: 126%;
    left: -50px;
  }
}

@include media-breakpoint-only(xl) {

}

@include media-breakpoint-only(xxl) {}

// @include media-breakpoint-between(lg, xl) {}