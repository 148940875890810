//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// select2
@import "select2-bs5";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

// Font Awesome
@import "fa-variables";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
// @import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  .navbar-brand {
    .logo {
      height: 44px;
    }
  }
}

.text-align-justify {
  text-align: justify;
}

.banner-text {
  text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
}

.bg-color {
  background-repeat: no-repeat;
  background-size: cover;
}

.btn {
  letter-spacing: 2px;

  &.btn-white {
    background-color: $white;
    border-color: $white;
    color: $seablue;
  }

  &.btn-green {
    background-color: $green;
    border-color: $green;
    color: $white;
  }
}

.navbar {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000;
  // background: #d3531a;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}


/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */

// .navbar-toggler.collapsed .toggler-icon {
//   background: linear-gradient(263deg, rgba(252, 74, 74, 1) 0%, rgba(0, 212, 255, 1) 100%);
// }

#main-content {
  padding-top: 86px;

  &.home {
    #banners {
      position: relative;

      .item {
        height: calc(100vh - 86px);
        min-height: 300px;

        ul {
          column-count: 2;
          line-height: 32px;
          font-size: 18px;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

}

#banners {
  .bg-img {
    background-position: 100%;
  }
}

#feature {
  background: rgb(226, 226, 228);
  background: -moz-linear-gradient(180deg, rgba(226, 226, 228, 1) 0%, rgba(226, 226, 228, 1) 45%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(226, 226, 228, 1) 0%, rgba(226, 226, 228, 1) 45%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(180deg, rgba(226, 226, 228, 1) 0%, rgba(226, 226, 228, 1) 45%, rgba(255, 255, 255, 1) 45%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e2e2e4", endColorstr="#ffffff", GradientType=1);

  &.warehouse-page {
    background: -moz-linear-gradient(180deg, rgba(226, 226, 228, 1) 0%, rgba(226, 226, 228, 1) 70%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(226, 226, 228, 1) 0%, rgba(226, 226, 228, 1) 70%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
  background: linear-gradient(180deg, rgba(226, 226, 228, 1) 0%, rgba(226, 226, 228, 1) 70%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 1) 100%);
    &>.container>.row {
      margin-top: -70px;
    }
  }

  .card {
    img {
      width: 60px;
    }

    p {
      font-size: 14px;
    }
  }
}

#why {
  img {
    height: 80px;
  }
}

#client {
  .border-3 {
    border-left: 1px solid $grey;
  }
}

#hero-section {
  // height: 590px;
}

#platform {
  figure {
    width: -webkit-fill-available;

    .figure-img {}
  }

  .platform-slider {
    .item {
      padding: 0 15%;
    }
  }
}

#faq {
  .accordion-button {
    font-weight: 500;

    &:not(.collapsed) {
      box-shadow: none;
    }
  }

  .accordion-body {
    line-height: normal;
  }
}

#about-text {
  background-position: 50% 0;
  .page-title {
    padding-top: 8.5rem;
  }
}

.text-gradient {
  background: rgb(0, 91, 137);
  background: -moz-linear-gradient(90deg, rgba(0, 91, 137, 1) 0%, rgba(0, 117, 176, 1) 14%, rgba(30, 142, 201, 1) 58%, rgba(122, 187, 67, 1) 80%);
  background: -webkit-linear-gradient(90deg, rgba(0, 91, 137, 1) 0%, rgba(0, 117, 176, 1) 14%, rgba(30, 142, 201, 1) 58%, rgba(122, 187, 67, 1) 80%);
  background: linear-gradient(90deg, rgba(0, 91, 137, 1) 0%, rgba(0, 117, 176, 1) 14%, rgba(30, 142, 201, 1) 58%, rgba(122, 187, 67, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#005b89", endColorstr="#7abb43", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#visi-misi {
  h1 {
    color: $seablue;
  }
}

#best-offer {
  padding: 7rem 0;
  background-position: center;
}

.form-delivery {
  .form-control {
    height: 58px;
    border-color: $grey;
  }

  .input-group-text {
    border-left-width: 0;
    border-color: $grey;
    background-color: #E2E2E4;
  }

  .input-group {
    .btn {
      padding: 13px 0;
      color: $black;
      border-left-width: 0;
      border-color: $grey;
      background-color: #E2E2E4;
    }
  }

  // .btn {
  //   padding: 13px 0;
  // }
}

#cek-resi {
  input {
    &::placeholder {
      font-weight: 600;
      font-size: 22px;
      color: #E2E2E4;
    }
  }

  .input-group-text {
    color: $black;
  }
}

#partner-feature {
  .card {
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  }

  img {
    height: 80px;
  }
}

#jadi-partner {
  img {
    height: 60px;
  }

  .step {
    width: 42px;
    background-color: $white;
    border-radius: 50%;
    color: $seablue;
  }
}

#footer {
  background-color: $darkblue;

  .navbar-brand .logo {
    height: 54px;
  }

  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: rgba(255, 255, 255, .7);
    }
  }

  .socials {
    .fa-inverse {
      color: $darkblue;
    }
  }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/slick";

//---------------------------------------------
// 4.Responsive
//---------------------------------------------

@import "responsive";