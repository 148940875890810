$slick-font-path: "../fonts/slick-carousel/";
// $slick-font-path: "../fonts/fontawesome/";
$slick-loader-path: "../images/";

// $slick-font-family: "slick" !default;
$slick-font-family: "Font Awesome 6 Free";

// $slick-arrow-color: $dark-gray !default;
$slick-dot-color: $white;
$slick-dot-color-active: $white;

// $slick-prev-character: "\2190" !default;
// $slick-next-character: "\2192" !default;
// $slick-dot-character: "\2022" !default;

$slick-prev-character: "\f053";
$slick-next-character: "\f054";
$slick-dot-character: "\f111";

// $slick-dot-size: 6px !default;
$slick-dot-size: 8px;

// $slick-opacity-default: 0.75 !default;
// $slick-opacity-on-hover: 1 !default;
// $slick-opacity-not-active: 1 !default;