//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: "Open Sans", sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #404040; // The default text color

// $primary: #0d6efd;
$primary: #1a6285;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
// custom colors
$seablue: #026588;
$darkblue: #1A6285;
$green: #40A649;
$grey: #404040;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

$spacer: 1rem;


// Navs

// // scss-docs-start nav-variables
// $nav-link-padding-y: .5rem !default;
// $nav-link-padding-x: 1rem !default;
// $nav-link-font-size: null !default;
// $nav-link-font-size: null !default;
// $nav-link-font-weight: null !default;
$nav-link-font-weight: 500;
// $nav-link-color: var(--#{$prefix}link-color) !default;
$nav-link-color: $seablue;
// $nav-link-hover-color: var(--#{$prefix}link-hover-color) !default;
// $nav-link-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
// $nav-link-disabled-color: var(--#{$prefix}secondary-color) !default;
// $nav-link-focus-box-shadow: $focus-ring-box-shadow !default;

// $nav-tabs-border-color: var(--#{$prefix}border-color) !default;
// $nav-tabs-border-width: var(--#{$prefix}border-width) !default;
// $nav-tabs-border-radius: var(--#{$prefix}border-radius) !default;
// $nav-tabs-link-hover-border-color: var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color !default;
// $nav-tabs-link-active-color: var(--#{$prefix}emphasis-color) !default;
// $nav-tabs-link-active-bg: var(--#{$prefix}body-bg) !default;
// $nav-tabs-link-active-border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg !default;

// $nav-pills-border-radius: var(--#{$prefix}border-radius) !default;
// $nav-pills-link-active-color: $component-active-color !default;
// $nav-pills-link-active-bg: $component-active-bg !default;

// $nav-underline-gap: 1rem !default;
// $nav-underline-border-width: .125rem !default;
// $nav-underline-link-active-color: var(--#{$prefix}emphasis-color) !default;
// // scss-docs-end nav-variables


// Navbar

// // scss-docs-start navbar-variables
// $navbar-padding-y: $spacer * .5 !default;
$navbar-padding-y: $spacer * 1;
// $navbar-padding-x: null !default;

// $navbar-nav-link-padding-x: .5rem !default;
$navbar-nav-link-padding-x: $spacer * 2;

// $navbar-brand-font-size:            $font-size-lg !default;
// // Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
// $navbar-brand-margin-end:           1rem !default;

// $navbar-toggler-padding-y:          .25rem !default;
// $navbar-toggler-padding-x:          .75rem !default;
// $navbar-toggler-font-size:          $font-size-lg !default;
// $navbar-toggler-border-radius:      $btn-border-radius !default;
// $navbar-toggler-focus-width:        $btn-focus-width !default;
// $navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

// $navbar-light-color:                rgba(var(--#{$prefix}emphasis-color-rgb), .65) !default;
$navbar-light-color: rgba($seablue, .65);
// $navbar-light-hover-color:          rgba(var(--#{$prefix}emphasis-color-rgb), .8) !default;
$navbar-light-hover-color: rgba($seablue, .8);
// $navbar-light-active-color:         rgba(var(--#{$prefix}emphasis-color-rgb), 1) !default;
$navbar-light-active-color: rgba($seablue, 1);
// $navbar-light-disabled-color:       rgba(var(--#{$prefix}emphasis-color-rgb), .3) !default;
// $navbar-light-icon-color:           rgba($body-color, .75) !default;
// $navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-light-toggler-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), .15) !default;
// $navbar-light-brand-color:          $navbar-light-active-color !default;
// $navbar-light-brand-hover-color:    $navbar-light-active-color !default;
// $navbar-light-brand-hover-color: $navbar-light-active-color !default;
// // scss-docs-end navbar-variables

// // scss-docs-start navbar-dark-variables
// $navbar-dark-color: rgba($white, .55) !default;
// $navbar-dark-hover-color: rgba($white, .75) !default;
// $navbar-dark-active-color: $white !default;
// $navbar-dark-disabled-color: rgba($white, .25) !default;
// $navbar-dark-icon-color: $navbar-dark-color !default;
// $navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-dark-toggler-border-color: rgba($white, .1) !default;
// $navbar-dark-brand-color: $navbar-dark-active-color !default;
// $navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
// // scss-docs-end navbar-dark-variables


// Accordion

// // scss-docs-start accordion-variables
// $accordion-padding-y:                     1rem !default;
// $accordion-padding-x:                     1.25rem !default;
// $accordion-color:                         var(--#{$prefix}body-color) !default;
// $accordion-bg:                            var(--#{$prefix}body-bg) !default;
// $accordion-border-width:                  var(--#{$prefix}border-width) !default;
$accordion-border-width: 3px;
// $accordion-border-color:                  var(--#{$prefix}border-color) !default;
$accordion-border-color: $darkblue;
// $accordion-border-radius:                 var(--#{$prefix}border-radius) !default;
$accordion-border-radius: 1rem;
// $accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

// $accordion-body-padding-y:                $accordion-padding-y !default;
// $accordion-body-padding-x:                $accordion-padding-x !default;

// $accordion-button-padding-y:              $accordion-padding-y !default;
// $accordion-button-padding-x:              $accordion-padding-x !default;
// $accordion-button-color:                  var(--#{$prefix}body-color) !default;
// $accordion-button-color: $darkblue;
// $accordion-button-bg:                     var(--#{$prefix}accordion-bg) !default;
// $accordion-transition:                    $btn-transition, border-radius .15s ease !default;
// $accordion-button-active-bg:              var(--#{$prefix}primary-bg-subtle) !default;
$accordion-button-active-bg: none;
// $accordion-button-active-color:           var(--#{$prefix}primary-text-emphasis) !default;

// $accordion-button-focus-border-color:     $input-focus-border-color !default;
// $accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;
$accordion-button-focus-box-shadow: none;

// $accordion-icon-width:                    1.25rem !default;
$accordion-icon-width: 2rem;
// $accordion-icon-color:                    $body-color !default;
$accordion-icon-color: $seablue;
// $accordion-icon-active-color:             $primary-text-emphasis !default;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
// $accordion-icon-transform:                rotate(-180deg) !default;
$accordion-icon-transform: rotate(90deg);

// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-color}' class='bi bi-arrow-down-circle-fill' viewBox='0 0 16 16'><path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z' /></svg>");
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-color}' viewBox='0 0 512 512'><path d='M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z' /></svg>");
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
// $accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-color}' class='bi bi-arrow-down-circle-fill' viewBox='0 0 16 16'><path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z' /></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$accordion-icon-color}' viewBox='0 0 512 512'><path d='M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z' /></svg>");
// // scss-docs-end accordion-variables